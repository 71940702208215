.App {
    font-family: 'Cabin', sans-serif;
    color: #333; /* Dark grey for text for better readability */
}

.header-container {
    background-color: #1A202C; /* Darker shade for a professional look */
    color: #FFF; /* White text color for contrast */
    padding: 40px 20px; /* Adequate padding for spacious look */
}

.content-container {
    padding: 20px;
    gap: 20px; /* Adds space between columns/rows */
}

.events-container {
    background: #fff; /* Light background for the events container */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    border-radius: 8px; /* Softened corners */
    overflow: hidden; /* Ensures nothing overflows the rounded corners */
}

.event-container {
    padding: 20px;
    border-bottom: 1px solid #E2E8F0; /* Light grey border for separation */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.event-container:last-child {
    border-bottom: none; /* Removes border from the last item */
}

.event-container:hover {
    background-color: #edf2f7; /* Lighter grey for hover */
}

.event-title, .event-summary {
    margin: 0; /* Removes default margins */
}

.event-title {
    font-size: 1.25rem; /* Larger font size for titles */
    font-weight: bold;
    margin-bottom: 8px; /* Adds some space below the title */
}

.event-summary {
    font-size: 1rem; /* Standard font size for summary */
    color: #4A5568; /* Slightly darker grey for readability */
}

/* Adjustments for mobile screens */
@media (max-width: 600px) {
    .header-container,
    .content-container {
        padding: 20px;
    }
    .events-container {
        margin-top: 0;
    }
}
