.background {
    background: linear-gradient(#FAD4C7, #FFFFFF);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 2rem;
}

.listed-features li {
    display: flex;
    flex-direction: row;
    list-style-type: none;

    & p {
        margin-left: .5rem;
        margin-top: .25rem;
    }
}

.slider {
    width: 100%;
    overflow: hidden;
}

ul {
    animation: slide-right 2s ease-out;
}

@keyframes slide-right {
    from {
        opacity: 0;
        margin-left: -100%;
        width: 300%;
    }

    to {
        opacity: 1;
        margin-left: 0%;
        width: 100%;
    }
    
}
